.cartContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.emptyCart {
  @extend %typo-body2;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  text-align: center;
  padding-block: var(--spacing-xs);
  width: calc(min(100%) - var(--page-inset) * 2);
  border-top: 1px solid var(--color-divider-inactive);
  border-bottom: 1px solid var(--color-divider-inactive);
  align-items: flex-start;

  @media (--small) {
    width: calc(min(100%) - var(--page-inset) * 3);
  }

  & a {
    color: var(--color-dark);
  }

  & button {
    @extend %typo-body2;
    padding: 0;
    /* color: var(--color-dark); */
    border: none;
    background: transparent;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
  }
}
