.summaryModal {
  background: var(--neutral-2);
  padding: var(--smart-page-inset);

  @media (--small) {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}

.subtotals {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  & span {
    display: flex;
    justify-content: space-between;
  }
}

.subtotalShipping {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-8);
}

.cart__actions {
  padding-top: var(--spacing-sm);

  & button {
    font-weight: 500;
  }
}

.voucher {
  border-top: 1px solid var(--color-divider);
  padding: var(--spacing-16) 0;
  margin-top: var(--spacing-16);
}

.summary {
  transition: opacity 500ms;
  padding-bottom: var(--spacing-xs);
  padding-top: var(--spacing-24);
  @extend %typo-body2;

  &.loading {
    opacity: 0.3;
  }
}
