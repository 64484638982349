.form {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: var(--spacing-16);

  & .inputWrap {
    width: 100%;
  }

  & > *:first-child {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.wrap {
  width: 100%;
}

.error {
  color: var(--color-error);
  margin-top: var(--spacing-8);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subContainer {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.codeContainer {
  display: flex;
  flex-direction: column;

  & .voucherLabel {
    font-size: 12px;
    margin-top: 19px;
  }

  & .voucherCode {
    padding-top: var(--spacing-8);
    text-decoration: 1px solid underline;
    font-weight: 500;
    height: 38px;
  }
}
