.item {
  width: 100%;
  display: flex;
  gap: var(--spacing-16);
  position: relative;
  border-bottom: 1px solid var(--color-divider-inactive);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-24);
  @extend %typo-caption1;

  &.finalized {
    padding: var(--spacing-xs) 0;
  }

  & .details__container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
  }

  &__meta {
    color: var(--color-text-secondary);
  }

  &__info {
    flex: 1;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.quantity {
  &__wrapper {
    display: flex;
    align-items: baseline;
  }
}

.image {
  width: 78px;
  height: 100px;
  flex-shrink: 0;
  background: var(--color-tertiary);

  & picture {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.details__container_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.left__column {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.item__name,
.discounted__wrapper {
  @extend %typo-subtitle3;
}

.discounted__price {
  color: var(--color-inactive-tag);
  text-decoration: line-through;
}

.right__column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
}
