.container {
  padding-block: var(--spacing-xs);
  @media (--small) {
    padding-top: var(--spacing-40);
    padding-bottom: var(--spacing-md);
  }

  & :global(.swiper-button-next) {
    right: var(--spacing-sm);
  }

  & :global(.swiper-button-prev) {
    right: calc(var(--spacing-md) + var(--spacing-sm));
    transform: translateX(-10%);
  }
}

.title {
  @extend %typo-body2;
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-xs);
  padding-top: var(--spacing-md);
  padding-bottom: 0;
  text-transform: uppercase;

  @media (--small) {
    padding-bottom: var(--spacing-xs);
  }
}

div.slide {
  width: 45%;
  @media (--small) {
    width: 33%;
  }

  &:first-child {
    margin-left: var(--spacing-sm);
  }
  &:last-child {
    margin-right: var(--page-inset);
  }
}

.cards_1 {
  grid-template-columns: 1fr;
  max-width: 50%;
}

.cards_2 {
  grid-template-columns: repeat(2, 1fr);
}

.cards_3 {
  grid-template-columns: repeat(3, 1fr);

  @media (--mobile-only) {
    grid-template-columns: repeat(2, 1fr);

    /* If there are more than 2 related products in upsell, hide the third card */
    & article:nth-child(n + 3) {
      display: none;
    }
  }
}

.cards_1,
.cards_2,
.cards_3 {
  display: grid;
  column-gap: var(--spacing-24);
}
