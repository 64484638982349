.quantity-container {
  --quantity-button-size: 30px;
  --count-icon-size: 12px;
  @extend %typo-body2;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: var(--quantity-button-size);

  & > button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    padding: 0;
    width: var(--quantity-button-size);
    height: var(--quantity-button-size);

    --svg-color: var(--color-dark);

    & svg {
      width: var(--count-icon-size);
      height: var(--count-icon-size);
      display: block;
      margin: 0 auto;
    }

    &:hover {
      background: var(--color-tertiary);
    }
  }
  & > p {
    margin: 0 var(--spacing-4);
    min-width: var(--spacing-16);
    text-align: center;
  }
}
