.container {
  padding: 0 var(--page-inset);

  & :global(.cart-toast) {
    width: unset;
    border-top: unset;
  }
}

.finalized.container {
  padding: 0;
}
