.input {
  border: 0;
  border-bottom: 1px solid var(--neutral-4);
  width: 100%;
  @extend %typo-body2;
  color: var(--color-dark);
  padding: var(--spacing-8) 0;

  &:hover,
  &:active,
  &:focus {
    border-color: var(--neutral-6);
  }

  &:focus-visible {
    outline: var(--color-dark);
  }
}

.inputWrap {
  margin: var(--spacing-16) 0;
}

.message {
  color: var(--color-error);
  display: none;
  @extend %typo-body2;
}

.invalid + .message {
  display: block;
  color: red;
  @extend %typo-caption1;
}

.label {
  @extend %typo-caption1;
  margin-bottom: var(--spacing-4);
}
