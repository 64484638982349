.modalInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalContent {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.title {
  @extend %typo-body2;
}

.modal__toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--spacing-xs);
  align-items: center;
  padding: var(--spacing-xs);
  --svg-color: var(--color-white);

  @media (--small) {
    padding: var(--spacing-sm) var(--spacing-xs);
  }

  &_actions {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);

    & .locationButton {
      @extend %typo-body2;
      padding-inline: var(--spacing-xs);
      background: transparent;
      color: var(--color-primary);

      @media (--small) {
        &:hover {
          background: var(--neutral-3);
        }
      }

      &.countryFlag {
        & img {
          --flag-width: 18px;
          width: var(--flag-width);
          margin-right: 2px;
        }
      }
    }

    & svg {
      width: var(--icon-sm);
      height: var(--icon-sm);
    }
  }
}

.modalFooter {
  margin-top: auto;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);

  @media (--small) {
    padding-bottom: 0;
  }
}
