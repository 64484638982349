.button {
  --button-padding-h: 24px;
  --button-padding-v: 12px;
  --button-br: 0;
  --button-gap: 8px;
  --svg-color: var(--color-white);

  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.01;
  display: inline-block;
  min-width: 80px;
  transition: all ease 150ms;
  cursor: pointer;
  align-items: center;
  background: var(--color-dark);
  color: var(--color-white);
  padding: var(--button-padding-v) var(--button-padding-h);
  border-radius: var(--button-br);
  appearance: none;
  border: 0;
  justify-content: center;
  text-decoration: none;
  line-height: 20px;
  text-align: center;
  /* display: flex; need to figure out how to handle icons better */
  @extend %typo-subtitle2;

  @media (--small) {
    &:hover {
      background: var(--neutral-7);
      color: var(--color-white);
      --svg-color: var(--color-white);
    }
  }

  &.outlined:not(:hover) {
    background: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    --svg-color: var(--color-primary);
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;

    &:hover {
      /*color: var(--color-text-disabled);*/
    }
  }

  & svg {
    width: 14px;
    height: 14px;
  }

  & path {
    transition: all ease 150ms;
  }

  & span {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;
  }
}

.icon {
  background: transparent;
  padding: 4px;
  min-width: 0;
  --svg-color: var(--color-dark);
  border-radius: 100%;

  & svg {
    width: var(--icon-lg);
    height: var(--icon-lg);
    display: block;
  }

  &:hover {
    --svg-color: var(--color-dark);
    background: var(--neutral-2);
  }
}

.invert {
  color: var(--color-dark);
  background: var(--color-white);
}

.link,
.toggle,
.unset {
  padding: 0;
  min-height: 0;
  min-width: 0;
  background: transparent;
  color: var(--color-dark);
  font-size: inherit;
  text-decoration: underline;
  --svg-color: var(--color-primary);

  &:hover {
    background: transparent;
    color: var(--color-dark);
    text-decoration: none;
    font-size: inherit;
    --svg-color: var(--color-primary);
  }
}

.unset {
  text-decoration: unset;
}

.toggle {
  &:hover {
    background: var(--neutral-2);
    text-decoration: none;
  }
}

.full {
  width: 100%;
  text-align: center;
  & span {
    width: 100%;
    display: block;
  }
}

.block {
  display: block;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid var(--neutral-4);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-bottom: -2px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
