.dialog {
  display: block;
  inset: 0;
  overflow: hidden;
  position: fixed;
  padding: 0;

  max-width: 100%;

  &:not([open]) {
    pointer-events: none;
    visibility: hidden;
  }

  &[open] {
    pointer-events: auto;
    visibility: visible;
  }

  & .content {
    height: 100%;
    width: 100%;
    overscroll-behavior: contain;
  }
}
