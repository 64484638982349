.drawer {
  --drawer-max-width: 548px;
  position: fixed;
  background-color: var(--neutral-2);
  height: 100%;
  max-width: min(var(--drawer-max-width), 100vw);
  max-height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  left: unset;
  border: none;
  z-index: var(--z-overlay);

  transform: translateX(100%);
  transition: all 0.3s ease;

  &::backdrop {
    animation: fadeIn 0.3s ease forwards;
    background-color: var(--color-backdrop);
  }

  &:not([open]) {
    animation: slideOut 0.3s ease forwards;
  }

  &[open] {
    animation: slideIn 0.3s ease forwards;
  }

  &.left {
    transform: translateX(-100%);
    &:not([open]) {
      animation: slideOutLeft 0.3s ease forwards;
    }

    &[open] {
      animation: slideInLeft 0.3s ease forwards;
    }
  }
}

.drawerContent {
  display: flex;
  flex-direction: column;
  padding-inline: var(--spacing-sm);
  overflow: auto;

  & .close {
    display: block;
    margin-left: auto;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    --svg-color: var(--color-dark);

    & svg {
      display: block;
      width: var(--icon-md);
      height: var(--icon-md);
    }
  }

  & .header {
    padding-block: var(--spacing-md);
  }

  & .footer {
    padding-block: var(--spacing-md);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
