.checkout {
  &__body {
    @extend %main-grid;
    margin: 0;
  }

  &__header {
    margin: var(--spacing-md) 0 0;

    & h2 {
      @extend %typo-headline2;
    }
  }

  &__step {
    @extend %main-left;
  }

  &__step {
    width: 100%;
    @extend %typo-body2;

    & .title {
      @extend %typo-body1;
      margin-top: var(--spacing-sm);
    }
  }

  &__cart {
    width: 100%;
    @extend %main-right;
  }

  &__cartsummary {
    padding-top: var(--spacing-sm);
  }

  &__indicator {
    display: flex;
    margin: var(--spacing-sm) 0;
    gap: var(--spacing-sm);

    & > div {
      height: 8px;
      width: 100%;
      background: var(--color-divider-inactive);

      &.active {
        background: var(--color-divider);
      }
    }
  }
}

.addAddress2Container {
  display: block;
  & button {
    margin-left: auto;
    display: block;
    margin-top: 4px;
    @extend %typo-body2;
  }
}

.label {
  @extend %typo-caption1;
}

.actions {
  margin-top: var(--spacing-md);
}

.error {
  color: var(--color-red);
  padding: var(--spacing-4);
}

.locationButton {
  @extend %typo-body2;
  background: transparent;
  color: var(--color-primary);
  padding: 0px;
  margin-top: 4px;

  & span {
    gap: 4px !important;
  }

  @media (--small) {
    &:hover {
      background: transparent;
      color: var(--color-primary);
    }
  }

  &.countryFlag {
    & img {
      --flag-width: 18px;
      width: var(--flag-width);
      margin-right: 2px;
    }
  }
}
